import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const OpsioOfferings = (opsioOfferingsData) => {
  return (
    <>
      <div
        className="ourApproachOpsioOfferings serviceHotspot pt-5"
        id="opsioOfferings"
      >
        <div className="containerwrap">
          <div className="approchTitle">
            {opsioOfferingsData.opsioOfferings.title}
          </div>
          <div className="approchHeading">
            {opsioOfferingsData.opsioOfferings.heading}
          </div>
          <div
            className="approchdescription mb-3"
            dangerouslySetInnerHTML={{
              __html: opsioOfferingsData.opsioOfferings.des,
            }}
          />
        </div>
      </div>
      <div className="processOpsioOfferings  serviceHotspot pt-0 pb-0">
        <div className="containerwrap">
          <div className="prorcss_wrap">
            <div className="ourApproachOpsioOfferings serviceHotspot pt-0 mb-0">
              <div
                className="approchHeading"
                dangerouslySetInnerHTML={{
                  __html: opsioOfferingsData.opsioOfferings.bulletHeading,
                }}
              />

              <div className="approchdescription w-100 mb-3">
                {opsioOfferingsData.opsioOfferings.des1}
              </div>
            </div>
            {opsioOfferingsData.opsioOfferings.bulletPoints && (
              <div className="d-md-flex ">
                <div className="processRightSectionOfferings w-100">
                  <div className="inner_process d-md-flex justify-content-md-center w-100">
                    <div
                      className={`inner_process_left d-md-flex ${
                        opsioOfferingsData.opsioOfferings.id !== "whyUs"
                          ? "justify-content-md-center"
                          : ""
                      }  w-100 col-md-12 col-12 flex-md-wrap`}
                    >
                      {opsioOfferingsData.opsioOfferings.bulletPoints.map(
                        (bulletPoint, index) => (
                          <div
                            className={`processDetails d-md-flex ${
                              opsioOfferingsData.opsioOfferings.id !== "whyUs"
                                ? "justify-content-md-center"
                                : ""
                            } flex-md-column col-md-3 col-12 ${
                              index === 3 || index === 7
                                ? "me-md-0 pe-md-0"
                                : ""
                            }`}
                            key={index}
                          >
                            <div className="number">
                              {opsioOfferingsData.icons &&
                              opsioOfferingsData.opsioOfferings.id ===
                                "platforms" ? (
                                <div
                                  className={`d-md-flex ${
                                    opsioOfferingsData.opsioOfferings.id ===
                                    "whyUs"
                                      ? "justify-content-md-center"
                                      : ""
                                  }`}
                                >
                                  <img
                                    src={opsioOfferingsData.icons[index].url}
                                    width={index === 3 ? "100px" : "50px"}
                                    height="50px"
                                    alt={bulletPoint}
                                  />
                                </div>
                              ) : (
                                <>
                                  {index < 9 ? `0${index + 1}` : `${index + 1}`}
                                </>
                              )}
                            </div>
                            <div
                              className={`process_content_solution_purple ${
                                opsioOfferingsData.opsioOfferings.id === "whyUs"
                                  ? "text-start"
                                  : "text-center"
                              }`}
                              dangerouslySetInnerHTML={{ __html: bulletPoint }}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {opsioOfferingsData.opsioOfferings.bulletPointsWithDes && (
              <div className="partnerSecTechnologies serviceHotspot pt-md-0">
                <div className="partSecwrapTechnologies pb-5">
                  <div className="partsecleftTechnologies partseccover">
                    <div className="problem-technologies serviceHotspot">
                      <Slider className="partner-slider">
                        {opsioOfferingsData.opsioOfferings.bulletPointsWithDes.map(
                          ({ title, des }, index) => (
                            <div key={index} className="partsecleft">
                              <div className="leftSection d-md-flex flex-md-column align-items-md-center w-100">
                                <div className="secHeading text-center">
                                  {title}
                                </div>
                                <p
                                  className="text-center"
                                  dangerouslySetInnerHTML={{ __html: des }}
                                />
                              </div>
                            </div>
                          )
                        )}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="ourApproachOpsioOfferings serviceHotspot pt-0">
          <div className="containerwrap">
            <div className="approchHeading">
              {opsioOfferingsData.opsioOfferings.heading2}
            </div>
            <div className="approchdescription mb-3">
              {opsioOfferingsData.opsioOfferings.des2}
            </div>
            <div className="approchdescription mb-3">
              {opsioOfferingsData.opsioOfferings.des3}
            </div>
          </div>
        </div>
        <div className="containerwrap">
          <div className="prorcss_wrap d-md-flex justify-content-md-center mt-5">
            <div className="processRightSectionOfferings">
              <div className="inner_process d-md-flex justify-content-md-center w-100 mt-3 mt-md-0 mt-lg-0">
                <div className="inner_process_left d-md-flex justify-content-md-center w-100 col-md-12 col-12 flex-wrap">
                  {opsioOfferingsData.opsioOfferings.highlights &&
                    opsioOfferingsData.opsioOfferings.highlights.map(
                      (highlight, index) => (
                        <div
                          className={`d-md-flex  ${
                            opsioOfferingsData.opsioOfferings.id ===
                              "opsioOfferings" ||
                            opsioOfferingsData.opsioOfferings.id ===
                              "advantages"
                              ? "col-md-4"
                              : "col-md-5"
                          }`}
                          key={index}
                        >
                          <div
                            className={`processDetails ${
                              (opsioOfferingsData.opsioOfferings.id ===
                                "useCasesForTerragrunt" &&
                                index === 1) ||
                              index === 3
                                ? "me-md-0 pe-md-0"
                                : ""
                            }  flex-wrap col-12`}
                          >
                            <div className="number">
                              {opsioOfferingsData.icons &&
                              opsioOfferingsData.opsioOfferings.id !==
                                "useCasesForTerragrunt" &&
                              opsioOfferingsData.opsioOfferings.id !==
                                "whyUs" &&
                              !!opsioOfferingsData.icons[index]?.url ? (
                                <div className="d-md-flex justify-content-md-center">
                                  <img
                                    src={opsioOfferingsData.icons[index].url}
                                    alt={highlight}
                                  />
                                </div>
                              ) : (
                                <>{`0${index + 1}`}</>
                              )}
                            </div>
                            <div
                              className={`process_content_solution_purple ${
                                opsioOfferingsData.opsioOfferings.id ===
                                  "useCasesForTerragrunt" ||
                                opsioOfferingsData.opsioOfferings.id === "whyUs"
                                  ? "text-start"
                                  : "text-center"
                              }`}
                            >
                              {highlight}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
