import React from "react";
import { navigate,Link } from 'gatsby';
import {Navigation} from "./Navigation";

export const Heading = ({herosection,navbar,slug,locale}) => {
    return (
        <div className="service_upper_class">
            <div className="serviceHeroSectionTechnologies " id="heading">
                <div className="containerwrap">
                    <div className="serviceHeroSectionTitle">{herosection.title}</div>
                    <h1 className="serviceHeroSectionHeading">{herosection.heading1}<span style={{color: "#58C9E8"}}>{herosection.heading2}</span></h1>
                    <div className="serviceHeroSectionDescription" dangerouslySetInnerHTML={{ __html: herosection.des1 }} />
                
                    <div className="opsBtnwrap middelBtn"><Link className="opsBtnlink" to={`/contact-us/`}>
                        <p>{herosection.btn}</p></Link>
                    </div>
                </div>
            </div>
            <Navigation data={navbar} locale={locale} slug={slug} />
        </div>
    )
}
