import React from 'react'
import { useState } from 'react';
import { navigate } from "gatsby";

export const Navigation=({data, locale, slug})=> {
    const [hashLink, setHashLink] = useState("");
    const handleClick = (val) => {
        setHashLink(val);
        navigate(`/${slug}/#${val}`, { replace: true })
    }

    React.useEffect(() => {

        let selectedHashLink = window?.location.href.split('#')[1]
        if(selectedHashLink) {
            setHashLink(selectedHashLink);
        }
    });

    return (
            <div className="pageNav solutionPage">
                <ul className="pageNav-ul">
                <li key={1} onClick={(e) => handleClick("overview")} className={hashLink === "overview" ? 'active':''}>{data.l1}</li>
                    <li key={2} onClick={(e) => handleClick("idealUseCases")} className={hashLink === "idealUseCases" ? 'active':''}>{data.l2}</li>
                    <li key={3} onClick={(e) => handleClick("theProblem")} className={hashLink === "theProblem" ? 'active':''}>{data.l3}</li>
                    <li key={4} onClick={(e) => handleClick("theSolution")} className={hashLink === "theSolution" ? 'active':''}>{data.l4}</li>
                    <li key={5} onClick={(e) => handleClick("opsioOfferings")} className={hashLink === "opsioOfferings" ? 'active':''}>{data.l5}</li>
                    <li key={6} onClick={(e) => handleClick("managedCloudServices")} className={hashLink === "managedCloudServices" ? 'active':''}>{data.l6}</li>
                  
                </ul>
            </div>
    )
}


