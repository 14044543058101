import React from "react";

export const Solution = (solutionData) => {

  return (
    <>
      <div className="ourApproachSolution serviceHotspot" id="theSolution">
        <div className="containerwrap">
          <div className="approchTitle">{solutionData.solution.title}</div>
          <div className="approchHeading">{solutionData.solution.heading}</div>
          <div
            className="approchdescription "
            dangerouslySetInnerHTML={{ __html: solutionData.solution.des1 }}
          />
          <div
            className="approchdescription "
            dangerouslySetInnerHTML={{ __html: solutionData.solution.des2 }}
          />
        </div>
      </div>
      <div className="process  pb-5 serviceHotspot ">
        <div className="containerwrap d-flex justify-content-center">
          <div className="prorcss_wrap  ">
            {solutionData.solution.bulletHeading && (
              <div className="ourApproachTechnologies d-md-flex justify-content-md-center serviceHotspot pt-0 w-100">
                <div className="approchHeading pb-md-5">
                  {solutionData.solution.bulletHeading}
                </div>
              </div>
            )}

            <div className="d-md-flex">
              <div className="processRightSectionServices w-100">
                <div className="inner_process d-md-flex justify-content-center w-100">
                  <div className="inner_process_left d-md-flex w-100 col-md-12 col-12 flex-md-wrap">
                    {solutionData.solution.bulletPoints &&
                      solutionData.solution.bulletPoints.map(
                        (bulletPoint, index) => (
                          <div
                            className={`processDetails ${
                              solutionData.solution.bulletPoints.length === 6
                                ? "col-md-4"
                                : "col-md-3"
                            } col-12 ${
                              index === 3 ||
                              index === 7 ||
                              index === 11 ||
                              index === 15
                                ? "me-md-0 pe-md-0"
                                : ""
                            }`}
                            key={index}
                          >
                            <div className="number">
                              {index < 9 ? `0${index + 1}` : `${index + 1}`}
                            </div>
                            <div className="process_content_solution ">
                              {bulletPoint}
                            </div>
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {solutionData.solution.highlights1 &&
          solutionData.solution.id === "adoptTerraform" && (
            <div className="containerwrap d-flex justify-content-center w-100">
              <div className="prorcss_wrap d-md-flex justify-content-md-center  col-md-12 col-12 w-100 ">
                <div className="processRightSectionSolutions col-md-12 col-12 w-100">
                  <div className="inner_process d-md-flex justify-content-md-center w-100 mt-3 mt-md-0 mt-lg-0 col-md-12 col-12">
                    <div className="inner_process_left d-md-flex justify-content-md-center w-100 col-md-12 col-12">
                      {solutionData.solution.highlights1.map(
                        (highlight, index) => (
                          <div
                            className="d-md-flex flex-md-column align-items-md-center col-md-4 col-12"
                            key={index}
                          >
                            <div className="processDetails">
                              <div className="number">
                                <div className="d-flex justify-content-center ">
                                  {index === 0 && (
                                    <img
                                      src={solutionData.icons[0].url}
                                      alt="aws logo"
                                    />
                                  )}
                                  {index === 1 && (
                                    <img
                                      src={solutionData.icons[1].url}
                                      alt="azure logo"
                                    />
                                  )}
                                  {index === 2 && (
                                    <img
                                      src={solutionData.icons[2].url}
                                      alt="gcp logo"
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="process_content_solution_purple text-center">
                                {highlight}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
      {solutionData.solution.heading2 && solutionData.solution.des3 && (
        <div className="ourApproachSolution pt-0 pb-5 serviceHotspot">
          <div className="containerwrap">
            <div className="approchHeading">
              {solutionData.solution.heading2}
            </div>
            <div className="approchdescription ">
              {solutionData.solution.des3}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
