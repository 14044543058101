import React from "react";
import check from "../../images/check.svg";

export const Overview = (overviewData) => {
  return (
    <div className="overview-technologies serviceHotspot" id="overview">
      <div className="containerwrap">
        <div className="overviewTitle">{overviewData.overview.title}</div>
        <h2 className="overviewHeading">{overviewData.overview.heading}</h2>
        <div className="overviewDescriptionSection lh-1">
          <div
            className="overviewDescriptionTechnologies text-center"
            dangerouslySetInnerHTML={{ __html: overviewData.overview.des }}
          />
        </div>
        {overviewData.overview.heading2 && (
          <>
            <h2 className="overviewHeading mt-md-5 mt-3">
              {overviewData.overview.heading2}
            </h2>
            <div className="overviewDescriptionSection lh-1">
              <div
                className="overviewDescriptionTechnologies text-center"
                dangerouslySetInnerHTML={{ __html: overviewData.overview.des1 }}
              />
            </div>
          </>
        )}
        <h2 className="overviewHeading mt-5">
          {overviewData.overview.benefitsTitle}
        </h2>
        <div className="d-md-flex justify-content-md-between col-md-12">
          <div className="overviewDescriptionSection me-md-5 col-md-6">
            <ul className="col-md-12 col-11 p-0">
              {overviewData.overview.benefits.map((benefit, index) => (
                <div className="d-flex col-md-12 col-11 ">
                  <span className="me-2">
                    <img src={check} width="20px" height="20px" />
                  </span>
                  <li
                    className="overviewDescriptionTechnologies col-md-11 col-10"
                    key={index}
                    dangerouslySetInnerHTML={{ __html: benefit }}
                  />
                </div>
              ))}
            </ul>
          </div>
          <div className="d-md-flex align-items-md-center d-flex justify-content-center col-md-6">
            <img
              src={overviewData.themeImages[0].url}
              className="overviewImageSize"
              alt={overviewData.overview.title}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
