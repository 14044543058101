import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Problem = ({
  problem: {
    id,
    title,
    heading,
    des1,
    des2,
    challengeHeading,
    challenges,
    challenges2,
    challengeDes,

    bulletPoints,
    bulletHeading2,
    bulletPoints2,
    des,
  },
}) => {
  return (
    <>
      {id === "consltancy" ? (
        <>
          <div
            className="ourApproachWhiteOfferings serviceHotspot"
            id="theProblem"
          >
            <div className="containerwrap">
              <div className="approchTitle">{title}</div>
              <div
                className="approchHeading mb-0"
                dangerouslySetInnerHTML={{ __html: heading }}
              />
              <div
                className="approchdescription mt-5"
                dangerouslySetInnerHTML={{ __html: des1 }}
              />
            </div>
          </div>
          <div className="processOpsioOfferings serviceHotspot pb-0 pt-5">
            <div className="containerwrap">
              <div className="prorcss_wrap w-100">
                <div className="d-md-flex col-md-12 w-100 justify-content-md-center ">
                  <div className="processRightSectionServices w-100">
                    <div className="inner_process d-md-flex justify-content-md-center w-100">
                      <div className="inner_process_left d-md-flex  w-100 col-md-12 col-12 flex-md-wrap">
                        {bulletPoints &&
                          bulletPoints.map((bulletPoint, index) => (
                            <div
                              className="processDetails col-md-3 col-12"
                              key={index}
                            >
                              <div className="number">
                                {index < 9 ? `0${index + 1}` : `${index + 1}`}
                              </div>
                              <div className="process_content_solution_purple">
                                {bulletPoint}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className="partnerSecTechnologies serviceHotspot pb-0"
            id="theProblem"
          >
            <div className="partSecwrapTechnologies pb-5">
              <div className="partsecleftTechnologies partseccover">
                <div className="problem-technologies serviceHotspot">
                  <div className="overviewTitle">{title}</div>
                  {challengeDes && (
                    <>
                      <div className="overviewDescriptionSection">
                        <div
                          className="overviewDescriptionTechnologies"
                          dangerouslySetInnerHTML={{ __html: challengeDes }}
                        />
                      </div>
                      <Slider className="partner-slider">
                        {challenges2.map(({ title, des }, index) => (
                          <div key={index} className="partsecleft">
                            <div className="leftSection d-md-flex flex-md-column align-items-md-center w-100">
                              <div className="secHeading text-center mb-2">
                                {title}
                              </div>
                              <p
                                className="text-center"
                                dangerouslySetInnerHTML={{ __html: des }}
                              />
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </>
                  )}
                  <div
                    className="overviewHeading mx-0 text-center"
                    dangerouslySetInnerHTML={{ __html: heading }}
                  />

                  <div className="overviewDescriptionSection">
                    <div
                      className="overviewDescriptionTechnologies"
                      dangerouslySetInnerHTML={{ __html: des1 }}
                    />
                  </div>
                  {des2 && (
                    <div className="overviewDescriptionSection">
                      <div
                        className="overviewDescriptionTechnologies"
                        dangerouslySetInnerHTML={{ __html: des2 }}
                      />
                    </div>
                  )}

                  <div className="overviewHeading mx-0 text-center my-5">
                    {challengeHeading}
                  </div>
                  <Slider className="partner-slider">
                    {challenges?.map(({ title, des }, index) => (
                      <div key={index} className="partsecleft">
                        <div className="leftSection d-md-flex flex-md-column align-items-md-center w-100">
                          <div className="secHeading text-center">{title}</div>
                          <p
                            className="text-center"
                            dangerouslySetInnerHTML={{ __html: des }}
                          />
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          {bulletHeading2 && (
            <div className="processOpsioOfferings serviceHotspot pb-0 pt-0">
              <div className="containerwrap">
                <div className="prorcss_wrap w-100">
                  <div className="ourApproachWhiteOfferings w-100 serviceHotspot pt-0 mb-0">
                    <div className="containerwrap">
                      <div className="approchHeading">{bulletHeading2}</div>
                    </div>
                  </div>
                  <div className="d-flex ">
                    <div className="processRightSectionServices w-100">
                      <div className="inner_process d-md-flex justify-content-md-center w-100">
                        <div className="inner_process_left d-md-flex justify-content-md-center  w-100 col-md-12 col-12 flex-md-wrap">
                          {bulletPoints2.map((bulletPoint, index) => (
                            <div
                              className={`processDetails col-md-5 col-12  ${
                                index === 3 || index === 7
                                  ? "me-md-0 pe-md-0"
                                  : ""
                              } ${
                                (id === "importance" && index === 1) ||
                                index === 3
                                  ? "me-md-0 pe-md-0"
                                  : ""
                              }`}
                              key={index}
                            >
                              <div className="number">
                                {index < 9 ? `0${index + 1}` : `${index + 1}`}
                              </div>
                              <div className="process_content_solution_purple">
                                {bulletPoint}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ourApproachWhiteOfferings w-100 serviceHotspot pt-0 mb-0 pb-md-5 text-white text-center ">
                <div className="containerwrap">
                  <div className="approchDescription">{des2}</div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
